<template>
	<div>
		<div style="padding:0 15px">
			<p class="title">
				{{tenderInfo.name}}
			</p>
			<p class="titleChild">项目编号：{{tenderInfo.number}}</p>
			<p class="titleChild">招标方式：{{tenderInfo.way}}</p>
			<p class="titleChild">发布时间：{{tenderInfo.release_time}}</p>
			<p class="titleChild">报名截止时间：{{tenderInfo.end_time}}</p>
		</div>
		
		<van-divider :style="{ borderColor: '#DCDFE6'}"/>
		<div style="padding: 0 15px 15px 15px;">
			<div v-html="tenderInfo.content" id="wangTable">
			</div>
			<p v-for="(item,index) in tenderInfo.path" :key="index" style="margin:5px 0;">
				<span>附件下载：</span>
				<a :href="loadFile(item.annex_path)" download style="text-decoration: none;color: grey;">{{item.annex_name}}</a>
			</p>
		</div>
		
	</div>
</template>

<script>
import Vue from 'vue';
import { Divider } from 'vant';
Vue.use(Divider)
export default {
	name:'tenderContent',
	data() {
		return {
			tenderInfo:{
				name:'',
				number:'',
				way:0,
				release_time:'',
				end_time:'',
				content:''
			}
		}
	},
	methods: {
		loadFile(path) {
			return this.baseUrl2+path;
		}		
	},
	mounted:function(){
			var that=this;
			let tenderId = window.atob(window.atob(this.$route.query.id));
			this.$axios.post(this.baseUrl+'admin/tender/searchTender?tenderInfoId='+tenderId).then(response=>{
				if(response.data.code!='200'){
					that.$alert(response.data.msg, '提示', {confirmButtonText: '确定'});
				}else{
					var info=response.data.data.res[0];
					this.tenderInfo={
						name:info.name,
						number:info.number,
						way:this.way[info.way-1].name,
						release_time:info.release_time,
						end_time:info.end_time,
						content:info.content,
						path:info.path
					}
				}
			}).catch(function (error) {window.console.log(error)});//ajax请求失败
			
			this.$axios.post(this.baseUrl+'admin/tender/visitAdd',{
				'tender_id':tenderId,
				'visit_ip':'',
				'visit_address':''
			}).then(response=>{
					console.log(response);
			}).catch(function (error) {window.console.log(error)});//ajax请求失败
	},

}
</script>

<style scoped>
	.title{font-size: 15px;font-weight: 600;}
	.titleChild{margin:3px 0;font-size: 14px;color: #606266;}
</style>
